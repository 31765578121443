export const ROOT = "/";
export const MONTHLY = "/monthly";
export const DAILY = "/daily";
export const BLOG = "/blogs";
export const BLOG_ARTICLE = "/articles";
export const POPE = "/pope";
export const SCHOOL_PRAYER = "/school-prayer";
export const CAMPAIGN_PAGE = "/campaigns";
export const SYNOD = "/synod";
export const SYNOD_PRAYER_PAGE = "/synod-prayer";
export const TEMPORAL = "/temporal-redirect";
export const ROSARY_PAGE = "/eRosary";
export const DONATE = "/donate";
export const COMMUNITY = "/community";
export const SHARE_PRAYER = "/prayers";
export const DEFAULT = "*";
export const PROFILE = "/profile";
export const WHAT_IS_CTP = "/what-is-ctp";
export const HOW_DOES_CTP = "/how-does-ctp-work";
export const LEGAL = "/legal-notice-privacy-policy-and-cookies";
export const POPE_PRAYER_EMBED = "/embed/pope-prayer";
