import { getPopeProfileData } from "services/services";

export async function fetchLatestPopePray(setPray) {
  try {
    const response = await getPopeProfileData(1);

    if (response.data?.[0]) {
      setPray(response.data[0]);
    }
  } catch (error) {
    console.log(error);
  }
}
