
export const branchOffices = [
  // Africa
  {
    code: "PWPN/EYM",
    name: "ANGOLA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Valter João Cassamano SJ",
        role: "National Coordinator",
        email: "gualbertocassamano@gmail.com",
      },
      {
        code: "EYM",
        name: "Félix Nambi",
        role: "National Coordinator",
        email: "felixnambi20@gmail.com",
      },
      {
        code: "AOP",
        name: "Domingos Canoquela",
        role: "National Coordinator",
        email: "dcanokela@gmail.com",
      },

    ],
  },
  {
    code: "MEJ",
    name: "BENIN",
    offices: [
      {
        code: "MEJ",
        name: "Flor Bianco ODN",
        role: "Contact",
        email: "florbiancoecj@gmail.com",
      },
    ],
  },
  {
    code: "RMPP/MEJ",
    name: "BURKINA FASO",
    offices: [
      {
        code: "RMPP",
        name: "Florent Boena",
        role: "National Coordinator",
        email: "flobo100@gmail.com",
      },
      {
        code: "MEJ",
        name: "P. Silbert Yameogo",
        role: "National Director",
        email: "silvereyameogo@gmail.com",
      },
    ],
  },
  {
    code: "RMPP/MEJ",
    name: "BURUNDI",
    offices: [
      {
        code: "RMPP",
        name: "P. Emmanuel Ndorimana SJ",
        role: "Regional Coordinator",
        email: "emmanuelndorimana5@gmail.com",
      },
      {
        code: "MEJ",
        name: "Abbé Remesha, Désiré Sept",
        role: "National Chaplain",
        email: "remd7@yahoo.fr",
      },
    ],
  },
  {
    code: "RMPP/MEJ",
    name: "CAMEROON",
    offices: [
      {
        code: "RMPP",
        name: "Armel Djitchouang",
        role: "National Coordinator",
        email: "lorent.armel@yahoo.com",
      },
      {
        code: "RMPP(MEJ)",
        name: "P. Hugues Christian Bokouamanga SJ",
        role: "National Chaplain",
        email: "hugueschristianbokouamanga@jesuits.net",
      },
    ],
  },
  {
    code: "MEJ",
    name: "CONGO BRAZZAVILLE",
    offices: [
      {
        code: "MEJ",
        name: " ",
        role: " ",
        email: " ",
      },
    ],
  },
  {
    code: "PWPN/EYM",
    name: "ETHIOPIA",
    offices: [
      {
        code: "PWPN",
        name: "Biruktawit Kassa",
        role: "National Coordinator",
        email: "biruktawitkassa@gmail.com",
      },
    ],
  },
  {
    code: "PWPN/EYM",
    name: "GABON",
    offices: [
      {
        code: "PWPN",
        name: "Jean Michel Megne",
        role: "National Coordinator",
        email: "jean.mmegne@gmail.com",
      },
      {
        code: "EYM",
        name: "Cédric Nkili",
        role: "National Coordinator",
        email: "wolfegangnkili@gmail.com",
      },
    ],
  },
  {
    code: "RMPP (MEJ)",
    name: "IVORY COAST",
    offices: [
      {
        code: "RMPP (MEJ)",
        name: "P. Michael Oladigui Olawy",
        role: "National Coordinator",
        email: "reseaumondialdeprieredupapeci@gmail.com",
      },
      {
        code: "MEJ",
        name: "Sopie Odette Yapi",
        role: "Responsable Nationale MEJ",
        email: "ritasophie82yapi@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "KENYA",
    offices: [
      {
        code: "EYM",
        name: "Fr. Jonas Kiplimo",
        role: "National Coordinator",
        email: "omilpik@gmail.com",
      },
    ],
  },
  {
    code: "RMPP (MEJ)",
    name: "MADAGASCAR",
    offices: [
      {
        code: "RMPP (MEJ)",
        name: "P. Christophe Zakavelo SJ",
        role: "National Director",
        email: "zj_christophe@yahoo.fr",
      },
    ],
  },
  {
    code: "PWPN",
    name: "MALAWI",
    offices: [
      {
        code: "PWPN",
        name: "Felix Phuka",
        role: "National Coordinator",
        email: "feliphuka@gmail.com",
      },
    ],
  },
  {
    code: "MEJ",
    name: "MAURITIUS ISLANDS",
    offices: [
      {
        code: "MEJ",
        name: "Johanne Quevauvilliers",
        role: "National Coordinator",
        email: "johannecunat@yahoo.fr",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "MOZAMBIQUE",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "P. Afonso Mucane SJ",
        role: "National Coordinator",
        email: "afonsomucanemumu@gmail.com",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "NIGERIA",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Micheal Onu Aghadi SJ",
        role: "National Director",
        email: "monusj@jesuits-anw.org",
      },
    ],
  },
  {
    code: "MEJ",
    name: "REPUBLICA DE GUINEA ECUATORIAL",
    offices: [
      {
        code: "MEJ",
        name: "Patricia Moguel Magaña RJM",
        role: "Contact",
        email: "patriciamoguelrjm@yahoo.com.mx",
      },
    ],
  },
  {
    code: "RMPP(MEJ)",
    name: "REP. DEM. CONGO",
    offices: [
      {
        code: "RMPP(MEJ)",
        name: "P. Gilbert MBAMBI KIKA-SISKA SJ",
        role: "National Director",
        email: "mbambikika@yahoo.fr",
      },
    ],
  },
  {
    code: "PWPN",
    name: "REP. SOUTH AFRICA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Chris Chatteris SJ",
        role: "National Coordinator",
        email: "chrischatterissj@gmail.com",
      },
    ],
  },
  {
    code: "RMPP (MEJ)",
    name: "RWANDA",
    offices: [
      {
        code: "RMPP (MEJ)",
        name: "Martin Murekezi",
        role: "National Coordinator",
        email: "rmpprwanda@gmail.com",
      },
      {
        code: "RMPP (MEJ)",
        name: "Abbé Gilbert KWITONDA",
        role: "National Chaplain",
        email: "gilbertkwitonda2018@gmail.com",
      },
      {
        code: "RMPP",
        name: "P. Emmanuel Ndorimana SJ",
        role: "Regional Coordinator",
        email: "emmanuelndorimana5@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "SIERRA LEONE",
    offices: [
      {
        code: "PWPN",
        name: "Gabriel Kofi Akpah SJ",
        role: "Contact",
        email: "kofigabriel@gmail.com / gakpahsj@jesuits-anw.org",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "TANZANIA",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Josephat Leander Kabutta SJ",
        role: "Regional Coordinator",
        email: "popesprayer@aorjesuits.org",
      },
    ],
  },
  {
    code: "RMPP (MEJ)",
    name: "TOGO",
    offices: [
      {
        code: "RMPP (MEJ)",
        name: "Nestor Gounteni Foeyeme",
        role: "National Coordinator",
        email: "foeyeme@yahoo.fr",
      },
    ],
  },
  {
    code: "PWPN",
    name: "UGANDA",
    offices: [
      {
        code: "PWPN",
        name: "Bro Sserugga Noa Mawaggali",
        role: "National Coordinator",
        email: "nmsseru@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "ZAMBIA",
    offices: [
      {
        code: "PWPN",
        name: "Ms. Setridah Banda",
        role: "National Coordinator",
        email: "setridahbanda3@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "ZIMBABWE",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Martin Kundari SJ",
        role: "National Coordinator",
        email: "kundarim3@gmail.com",
      },
      {
        code: "PWPN",
        name: "Fr. Eusebio Mariano Samuel SJ",
        role: "Delegate",
        email: "mariseu99@gmail.com",
      },
    ],
  },
  // Europa
  {
    code: "PWPN",
    name: "AUSTRIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Toni Witner SJ",
        role: "National Director",
        email: "toni.witwer@jesuiten.org",
      },
    ],
  },
  {
    code: "PWPN",
    name: "NETHERLANDS ANDS FLANDERS",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Bart Beckers SJ",
        role: "Regional Coordinator",
        email: "bbeckers@jesuits.net",
      },
    ],
  },
  {
    code: "RMPP",
    name: "BELGIUM (FR)",
    offices: [
      {
        code: "RMPP",
        name: "",
        role: "National Coordinator",
        email: "",
      },
    ],
  },
  {
    code: "PWPN",
    name: "CROATIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Zvonko Vlah SJ",
        role: "National Director",
        email: "zvlah@globalnet.hr",
      },
      {
        code: "PWPN",
        name: "Nelica Validžić",
        role: "Contact",
        email: "nelica.validzic333@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "CZECH REPUBLIC",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Pavel Ambros SJ",
        role: "National Director",
        email: "pavel.ambros@upol.cz",
      },
    ],
  },
  {
    code: "PWPN",
    name: "DENMARK",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Herbert Krawczyk SJ",
        role: "National Director",
        email: "herbert@katolsk-aarhus.dk",
      },
    ],
  },
  {
    code: "RMPP",
    name: "FRANCE",
    offices: [
      {
        code: "RMPP",
        name: "P. Jean-Luc Fabre SJ",
        role: "National Director",
        email: "jean-luc.fabre@jesuites.com",
      },
      {
        code: "MEJ",
        name: "Sophie Lassagne",
        role: "National Director",
        email: "direction@mej.fr",
      },
      {
        code: "MEJ",
        name: "Jonathan Dolidon",
        role: "National Chaplain",
        email: "jdolidon@jesuites.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "GERMANY",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Dag Heinrichowski SJ",
        role: "National Coordinator",
        email: "dag.heinrichowski@jesuiten.org",
      },
    ],
  },
  {
    code: "PWPN",
    name: "GREECE",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Pierre Chongk Tzoun-Chan SJ",
        role: "National Coordinator",
        email: "pierre.hong@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "HUNGARY",
    offices: [
      {
        code: "PWPN",
        name: "Fr. György Hiba SJ",
        role: "National Coordinator",
        email: "hiba.gyorgy@jezsuita.hu",
      },
    ],
  },
  {
    code: "PWPN",
    name: "IRELAND",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Donal Neary SJ",
        role: "National Director",
        email: "dneary@messenger.ie",
      },
    ],
  },
  {
    code: "RMPP/MEG",
    name: "ITALIA",
    offices: [
      {
        code: "RMPP/MEG",
        name: "Fr. Renato Colizzi SJ",
        role: "National Director",
        email: "colizzi.r@gesuiti.it",
      },
    ],
  },
  {
    code: "PWPN",
    name: "LITUANIA",
    offices: [
      {
        code: "PWPN",
        name: "P. Mindaugas Malinauskas SJ",
        role: "National Coordinator",
        email: "mindaugas.malinauskas@jezuitai.lt",
      },
    ],
  },
  {
    code: "RMPP",
    name: "LUXEMBOURG",
    offices: [
      {
        code: "RMPP",
        name: "Michele and Isabella Camposeo",
        role: "National Coordinators",
        email: "prieredupape@cathol.lu",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "MALTA",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Raymond Pace SJ",
        role: "National Coordinator",
        email: "raypacesj@gmail.com",
      },
    ],
  },
  {
    code: "PWPN/EYM",
    name: "POLAND",
    offices: [      
      {
        code: "PWPN",
        name: "P. Robert Więcek SJ",
        role: "National Director",
        email: "am@ampolska.co",
      },
      {
        code: "EYM",
        name: "Fr. Marcin Nowak",
        role: "National Coordinator",
        email: "dyrektor@erm.pl",
      },
    ],
  },
  {
    code: "PWPN",
    name: "PORTUGAL",
    offices: [
      {
        code: "PWPN",
        name: "P. Antonio de Magalhaes Sant’Ana SJ",
        role: "National Director",
        email: "diretor.nacional@rmop.pt",
      },
    ],
  },
  {
    code: "PWPN",
    name: "RUMANIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Olivo Bosa SJ",
        role: "National Coordinator",
        email: "bosa.o@iezuiti.ro",
      },
    ],
  },
  {
    code: "PWPN",
    name: "SLOVAKIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Jozef Bartkovjak SJ",
        role: "National Coordinator",
        email: "jozef.b@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "SLOVENIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Miodrag Kekić SJ",
        role: "National Coordinator",
        email: "mio.kekic1@gmail.com",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "SPAIN",
    offices: [
      {
        code: "RMOP",
        name: "David Fornieles",
        role: "National Director",
        email: "davidfornieles@redoraciondelpapa.es",
      },
    ],
  },
  {
    code: "PWPN",
    name: "SWEDEN",
    offices: [
      {
        code: "PWPN",
        name: "Cristina Ramos de Strandberg",
        role: "Contact",
        email: "cristinaramosdestrandberg@gmail.com",
      },
    ],
  },
  {
    code: "RMPP",
    name: "SWITZERLAND (FR)",
    offices: [
      {
        code: "RMPP",
        name: "Dominique Schenker",
        role: "National Responsable",
        email: "dominique.schenker@hefr.ch",
      },
    ],
  },
  {
    code: "PWPN",
    name: "UNITED KINGDOM",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Eddy Bermingham SJ ",
        role: "National Coordinator",
        email: "eberminghamsj@jesuit.org.uk",
      },
    ],
  },
  {
    code: "PWPN",
    name: "WALLONY - BRUSSELS",
    offices: [
      {
        code: "PWPN",
        name: "Pierre Siasia Gisengilambanza and Lydie Eketebi Kalunga ",
        role: "Regional Coordinators",
        email: "prieredupape@catho.be",
      },
    ],
  },
  // Asia - Oceania
  {
    code: "PWPN",
    name: "AUSTRALIA",
    offices: [
      {
        code: "PWPN",
        name: "John and Anne Gray",
        role: "National Coordinator",
        email: "john.a.gray88@gmail.com / annedecagray@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "BANGLADESH",
    offices: [
      {
        code: "PWPN",
        name: "Br. Lawrence Subal Rozario CSC",
        role: "National Coordinator",
        email: "subalcsc@gmail.com",
      },
    ],
  },
  {
    code: "MEJ",
    name: "EAST TIMOR",
    offices: [
      {
        code: "MEJ",
        name: "",
        role: "",
        email: "",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "MICRONESIA",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Thomas G. Benz SJ",
        role: "Person in charge",
        email: "tbenz@jesuits.org",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "HONG KONG",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Antonius Reynolds Balubun, sdv",
        role: "National Coordinator",
        email: "baauhk@yahoo.com",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "INDIA",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr Jagdish Parmar SJ",
        role: "National Director",
        email: "pwpn.eym@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "INDONESIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Antonius Sumarwan SJ",
        role: "National Director",
        email: "marwansj@gmail.com",
      },
      {
        code: "EYM",
        name: "Fr. Yohanes Nugroho SJ",
        role: "National Coordinator",
        email: "johnugsj@jesuits.net",
      },
    ],
  },
  {
    code: "PWPN",
    name: "JAPAN",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Shogo Sumita SJ",
        role: "National Director",
        email: "sumitashogo2023@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "MALAYSIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Alberto Irenus David SJ",
        role: "Regional Coordinator",
        email: "pwpn.msb@mas-jesuits.org",
      },
    ],
  },
  {
    code: "EYM",
    name: "NOUVELLE CALEDONIA",
    offices: [
      {
        code: "EYM",
        name: "",
        role: "",
        email: "",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "PHILIPPINES",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Victor De Jesus SJ",
        role: "National Director",
        email: "national.director.pwpnap.phi@gmail.com",
      },
    ],
  },

  {
    code: "PWPN",
    name: "SOUTH KOREA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Joseph Woo-Bae Sohn SJ",
        role: "National Coordinator",
        email: "woobae@gmail.com",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "SRI LANKA",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Patrick Stephen Fernando SJ",
        role: "National Coordinator",
        email: "pat22659@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "TAIWAN",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Joseph Dang Do Nhan SJ",
        role: "National Coordinator",
        email: "dangdonhan@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "THAILAND",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Paul Kriangyot SJ",
        role: "National Coordinator",
        email: "paulkriangyot@jesuits.net",
      },
    ],
  },
   {
    code: "PWPN",
    name: "TIMOR-LESTE",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Venâncio Pereira SJ",
        role: "National Coordinator",
        email: "anaziopereira@gmail.com",
      },
    ],
  },
  {
    code: "PWPN/EYM",
    name: "VIETNAM",
    offices: [      
      {
        code: "PWPN",
        name: "Fr. Thomas Vu Ngoc Tin SJ",
        role: "National Coordinator",
        email: "tannviet@gmail.com",
      },
      {
        code: "EYM",
        name: "Fr. Joseph Ngô Viết Tấn SJ",
        role: "General Chaplain",
        email: "tannviet@gmail.com",
      },
    ],
  },
  // Medio Oriente
  {
    code: "EYM",
    name: "EGYPT",
    offices: [
      {
        code: "EYM",
        name: "Fr. Magdi-Nazmi SJ",
        role: "National Chaplain",
        email: "magdinazmi@yahoo.com",
      },
    ],
  },
  {
    code: "EYM",
    name: "LEBANON",
    offices: [
      {
        code: "EYM",
        name: "Fr. Magdi-Nazmi SJ",
        role: "National Chaplain",
        email: "en.mej.liban@gmail.com",
      },
      {
        code: "EYM",
        name: "Marie-Belle El Rahi",
        role: "National Coordinator",
        email: "rahimariebelle@hotmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "MIDDLE EAST",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Rabih el-Hourani SJ",
        role: "Regional Coordinator",
        email: "elhouranyrabih@jesuits.net",
      },
    ],
  },
  // America
  {
    code: "RMOP (MEJ)",
    name: "ARGENTINA-URUGUAY",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "P. Cristian Marín SJ",
        role: "Regional Director",
        email: "cristian.marin@rmoparu.org",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "BOLIVIA",
    offices: [
      {
        code: "PWPN",
        name: "Gonzalo and Roxana Cárdenas",
        role: "National Coordinators",
        email: "goncarde@gmail.com / pereira.roxana@gmail.com",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "BRASIL",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "P. Eliomar Ribeiro SJ",
        role: "National Director",
        email: "eliomarsj@hotmail.com",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "CHILE",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "Maritza Perez Guzman",
        role: "National Director",
        email: "directornacional@aomej.cl",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "COLOMBIA",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "P. Carlos Franco Revello SJ",
        role: "National Director",
        email: "rmopcolombia@jesuitas.org.co",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "COSTA RICA",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "Katherine Cavallini Fernández",
        role: "National Coordinator",
        email: "grupocavallini@gmail.com",
      },
    ],
  },
  {
    code: "RMOP",
    name: "CUBA",
    offices: [
      {
        code: "RMOP",
        name: "P. Juan de Jesús Jiménez Portes SJ",
        role: "National Coordinator",
        email: "jjjsj@hotmail.com",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "ECUADOR",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "P. Renato Argüello SJ",
        role: "National Coordinator",
        email: "arguellorenato9@gmail.com",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "EL SALVADOR",
    offices: [      
      {
        code: "RMOP",
        name: "Nery José Quevedo Madrid",
        role: "National Coordinator",
        email: "nery.quevedo17@gmail.com",
      },
      {
        code: "MEJ",
        name: "Cony Margarita Calzadilla Rodriguez",
        role: "National Coordinator",
        email: "ccalzadilla22@gmail.com",
      },
    ],
  },
  {
    code: "RMOP",
    name: "GUATEMALA",
    offices: [
      {
        code: "RMOP",
        name: "William Armira",
        role: "National Coordinator",
        email: "coordinacion@rmopguatemala.org",
      },
    ],
  },
  {
    code: "MEJ",
    name: "HAITI",
    offices: [
      {
        code: "MEJ",
        name: "Luce Mirla Paul Dorizan",
        role: "National Coordinator",
        email: "lucemirlap13@gmail.com",
      },
      {
        code: "MEJ",
        name: "P. Eric Jasmin",
        role: "National Chaplain",
        email: "ericjasmin2003@yahoo.fr",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "MEXICO",
    offices: [
      {
        code: "RMOP",
        name: "P. Nerio Solís SJ",
        role: "National Coordinator",
        email: "neriosolis.sj@gmail.com",
      },
      {
        code: "MEJ",
        name: "Hna. Nilvia Madera Suaste, rjm",
        role: "National Coordinator",
        email: "nilvimadera@gmail.com",
      },
    ],
  },
    {
    code: "RMOP",
    name: "PANAMÁ",
    offices: [
      {
        code: "RMOP",
        name: "Hna. Anilba Duarte",
        role: "National Coordinator",
        email: "anilba_duarte@yahoo.es",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "PARAGUAY",
    offices: [
      {
        code: "RMOP",
        name: "P. Gusman Servín Silvero SJ",
        role: "National Director",
        email: "gservinsilvero@gmail.com",
      },
      {
        code: "MEJ",
        name: "Lidia Carlota Carbajal Diaz ODN",
        role: "National Director",
        email: "lidia.carbajal@cdm.edu.co",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "PERU",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "P. José Luis Gordillo SJ",
        role: "National Coordinator",
        email: "josegordillosj@gmail.com",
      },
    ],
  },
  {
    code: "RMOP",
    name: "REP. DOMINICANA",
    offices: [
      {
        code: "RMOP",
        name: "P. Eduardo Llorens SJ",
        role: "National Coordinator",
        email: "ellorens@loyola.edu.do",
      },
    ],
  },
  {
    code: "PWPN",
    name: "TRINIDAD Y TOBAGO",
    offices: [
      {
        code: "PWPN",
        name: "",
        role: "",
        email: "",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "URUGUAY",
    offices: [
      {
        code: "RMOP",
        name: "P. Cristian Marín SJ",
        role: "Regional Coordinator",
        email: "cristian.marin@rmoparu.org",
      },
    ],
  },
  {
    code: "RMOP",
    name: "VENEZUELA",
    offices: [
      {
        code: "RMOP",
        name: "P. Alvaro Lacasta SJ",
        role: "National Director",
        email: "aporlacasta@hotmail.com",
      },
    ],
  },
  {
    code: "PWPN/EYM",
    name: "U.S.A.",
    offices: [      
      {
        code: "PWPN",
        name: "Fr. Joseph Laramie SJ",
        role: "National Director",
        email: "frlaramiesj@popesprayerusa.net",
      },
      {
        code: "EYM",
        name: "P. Joseph Thong Dinh Ngo CSsR",
        role: "National Coordinator",
        email: "thong.ngo@veym.net",
      },
    ],
  },
  {
    code: "PWPN",
    name: "CANADA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Edmund Lo SJ",
        role: "National Coordinator",
        email: "elo@jesuits.org",
      },
      {
        code: "EYM",
        name: "Sr. Lourdes Varguez RJM",
        role: "National Coordinator",
        email: "eymcanadamej@gmail.com",
      },
    ],
  },
];