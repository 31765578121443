import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import es from "./locales/es.json";
import zh from "./locales/zh.json";
import de from "./locales/de.json";
import it from "./locales/it.json";
import pt from "./locales/pt.json";
import fr from "./locales/fr.json";
const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  zh: {
    translation: zh,
  },
  de: {
    translation: de,
  },
  it: {
    translation: it,
  },
  pt: {
    translation: pt,
  },
  fr: {
    translation: fr,
  },
};

const config = {
  resources,
  lng: localStorage.getItem("lenguage")
    ? localStorage.getItem("lenguage")
    : navigator.language.split("-")[0],
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
};

export const resetI18n = async (addConfig = {}) => {
  await i18n
    .use(initReactI18next)
    .init({
      ...i18n.options,
      ...config,
      ...addConfig,
    });
};

export default resetI18n;
