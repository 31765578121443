import React, { useEffect, useState } from "react";
import resetI18n from "i18n/reset";

import { languagesSelect } from "helpers";
import PopePrayerView from "./PopePrayerView";
import { fetchLatestPopePray } from "./PopePrayerFunctions";

export function PopePrayerContainer() {
  const [pray, setPray] = useState(null);

  useEffect(() => {
    const checkLanguage = async () => {
      let lng = new URLSearchParams(window.location.search).get("lang")?.toLocaleLowerCase() || "en";
      const existLang = languagesSelect.find((ln) => ln.id === lng)
      if (existLang) {
        localStorage.setItem("lenguage", lng)
        await resetI18n({ lng: lng });
      }

      try {
        fetchLatestPopePray(setPray);
      } catch (error) {
        console.log(error);
      }
    }

    checkLanguage();
  }, []);

  if (!pray) {
    return null;
  }

  return (
    <PopePrayerView pray={pray} />
  );
}
