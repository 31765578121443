/* eslint-disable */
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  popePrayerContent: {
    overflowX: "hidden",
    maxWidth: "350px",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "5px",
  },
  popePrayerContainer: () => {
    let style = {
      boxSizing: "border-box",
      borderBottomLeftRadius: "6px",
      borderBottomRightRadius: "6px",
      height: "440px",
      overflowY: "hidden"
    };

    return style;
  },
  popePrayerHeader: {
    backgroundColor: "#444C67",
    width: "100%",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    height: "33px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  popePrayerHeaderTitle: {
    padding: "0, 10px",
    fontFamily: "Nunito Sans",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "center",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#FFFFFF",
  },
  popePrayerBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "100%",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    padding: "0 20px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  },
  popePrayerAvatar: {
    width: "78px",
    height: "78px",
  },
  popePrayerPopeSection: {
    display: "flex",
    padding: "10px 0",
    gap: "0 20px",
  },
  popePrayerPopeTitle: {
    padding: "0",
    height: "24px",
    top: "24px",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#444C67",
    margin: 0
  },
  popePrayerDate: {
    padding: "0",
    height: "16px",
    top: "52px",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#5D688E",
    margin: "4px 0 0 0",
  },
  popePrayerPopeDescription: {
    fontFamily: "Nunito Sans",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#444C67",
    margin: 0
  },
  popePrayerContentWrapper: {
    height: "226px",
    padding: "0 0 10px 0",
    display: "flex",
    flexDirection: "column",
  },
  popePrayerContentContainer: {
    flexGrow: 1,
    "&>div": {
        "&>div": {
          scrollbarWidth: "thin",
          scrollbarColor: "#797979 white",

          "&::-webkit-scrollbar": {
            "-webkit-appearance": "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#797979",
            borderRadius: "10px",
            border: "2px solid #f1f2f3",
            outline: "none",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar:vertical": {
            width: "10px",
          },
          "&::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button": {
            display: "none",
          },
        },
      },
  },
  popePrayerContentConntainerText: {
  },
  popePrayerContentText: {
    fontFamily: "Nunito Sans",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#444C67",
    maxHeight: "100%",
    overflowY: "auto",
    paddingBottom: 10,

    margin: "10px 0px 0px",
    overflow: "hidden",
    whiteSpace: "pre-line",
  },
  popePrayerFooterContainer: {
    textDecoration: "none"
  },
  popePrayerFooter: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "20px",
    paddingBottom: "20px",
    borderTop: "1px solid #6385B133",
    textDecoration: "none",
  },
  popePrayerCTPIcon: {
    width: "26px",
    height: "26px",
  },
  popePrayerCPTContainer: {
    display: "flex",
    gap: "0 10px",
    alignItems: "center",
  },
  popePrayerCPTTitle: {
    fontFamily: "Nunito Sans",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#444C67",
    margin: 0
  },
  popePrayerCPTArrow: {
    marginRight: "10px"
  }
});
